import React, { useState, useEffect } from "react";
import { Loader2 } from 'lucide-react';
import { getNFTsForUser, transferNFT, identity, getHodlersForUser } from "deso-protocol";

const NFTPurchaseCard = ({ currentUser, handlePurchaseRandomNFT, tokensHeld, tokensHeldNumber, tokensFetched }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [userTokens, setUserTokens] = useState(0);
  const [availableNFTs, setAvailableNFTs] = useState(false);

  const checkAvailableNFTs = async () => {
    const swapPublicKey = process.env.REACT_APP_SWAP_PUBLIC_KEY
    const minterPublicKey = process.env.REACT_APP_MINTER_PUBLIC_KEY
      try {
        const getNFTs = await getNFTsForUser({
          UserPublicKeyBase58Check: swapPublicKey,
        });
        console.log(getNFTs)
  
        if (!getNFTs || Object.keys(getNFTs.NFTsMap).length === 0) {
          console.log("No get nfts NFTS")
          console.log(getNFTs)
          setAvailableNFTs(false); // No NFTs available
        } else {
          // Filter NFTs by the specified creator public key
          const filteredNFTs = Object.keys(getNFTs.NFTsMap).filter((key) => {
            const postEntryResponse = getNFTs.NFTsMap[key].PostEntryResponse;
            return (
              postEntryResponse.ProfileEntryResponse?.PublicKeyBase58Check === minterPublicKey &&
              !postEntryResponse.Body.startsWith("Shark Gang Lottery") &&
              !postEntryResponse.Body.includes("Shark Gang - Mint Pass") &&
              postEntryResponse.PostExtraData["Collection Name"] === "Shark Gang"
            );
          });
          console.log("filtered nfts")
          console.log(filteredNFTs)
  
          setAvailableNFTs(filteredNFTs.length > 0); // Set true if NFTs match, otherwise false
        }
      } catch (error) {
        console.log(error)
        setAvailableNFTs(false); // Error case, no NFTs available
      }
  };

  useEffect(() => {
    checkAvailableNFTs();
  }, [currentUser]); // Add currentUser as a dependency to ensure the function runs when the user changes
  
  

  return (
    <div className="p-2 flex flex-col text-center overflow-x-hidden bg-white bg-opacity-95 mx-2 rounded-2xl border-2 border-slate-200 shadow w-full">
            <div className="flex flex-col gap-4 items-center justify-center p-4 rounded-2xl mt-2">
        <div className="font-bold text-center text-xl mt-4">
          Swap SharkGang Tokens for a Random SharkGang NFT
        </div>

        {/* User Balance */}
        { currentUser && (
        <div className="w-full bg-white border-2 rounded-xl p-2">
          <div className="text-black font-bold">
          Your token balance: 
          {tokensFetched ? (
            <span className="ml-1">{tokensHeld}</span>
          ) : (
            <span className="inline-flex items-center ml-2">
              <Loader2 className="animate-spin" size={16} style={{ verticalAlign: 'middle' }} />
            </span>
          )}
          </div>
        </div>
        )}

        {/* Error Message Display */}
        {errorMessage && (
          <div className="text-red-500 font-bold mt-2">{errorMessage}</div>
        )}

        {/* Available NFTs */}
        {currentUser ? (availableNFTs ? (
            <div className="flex overflow-x-auto gap-2 pb-0 nft-display max-w-full">
              <img src="/assets/random-shark.png" alt="Random SharkGang NFT" className="mb-4 w-64 z-0" />
            </div>
          ) : (
            <div className="text-black">No NFTs available to swap.</div>
          )
        ) : (
          <div className="text-red-500 font-bold mb-4">Log in to buy NFTs.</div>
        )}
      </div>

      {/* Purchase Button */}
      <div className="w-full flex flex-col items-center justify-center rounded-xl py-4 mt-4">
        <button
          onClick={handlePurchaseRandomNFT}
          className="custom-button mt-4 bg-blue-500 text-white rounded-md px-4 py-2"
          disabled={!currentUser || tokensHeldNumber < 1000}
        >
          {currentUser ? "Purchase NFT" : "Connect Wallet"}
        </button>
      </div>
    </div>
  );
};

export default NFTPurchaseCard;
